/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { WasteLabelingWidget } from '../../../_metronic/partials/widgets'


const UsersPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
     <div className='col-xxl-4'>
        <WasteLabelingWidget
          className='card-xl-stretch mb-xl-8'
        />
      </div>
    </div>
   
  </>
)

const LabelingAnalytics: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.LABELING'})}</PageTitle>
      <UsersPage />
    </>
  )
}

export {LabelingAnalytics}
