/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { apiURL } from '../../../../constants'
import { mainColor } from '../../../../constants'
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Box, Button } from '@mui/material'
import { positions } from '@mui/system';
import { borders } from '@mui/system';
import { json } from 'stream/consumers'

const imageHeight = 180;
const imageWidth = 80;

const columns: GridColDef[] = [
  {
    field: 'BlobUrl',
    headerName: 'Фото',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 250,
    renderCell: (params) => {
      var label = JSON.parse(params?.row.Label!)
      //console.log(label)
      return (
        <div style={{padding: 0, margin: 0, width: label.imageDimensionsScreen[0]/3, height: label.imageDimensionsScreen[1]/3, position: "relative"}}>
        <img style={{zIndex: -1}} src={params?.row.BlobUrl} alt="Img" width={label.imageDimensionsScreen[0]/3}  height={label.imageDimensionsScreen[1]/3}/>
        <div style={{zIndex: 1000, position: 'absolute', border: "2px solid green",
            left: label.bboxDimensionsScreen[0]/3 +"px",
            top: label.bboxDimensionsScreen[1]/3 +"px",
            width: label.bboxDimensionsScreen[2]/3 +"px",
            height: label.bboxDimensionsScreen[3]/3 +"px",
            }}></div>
        </div>
      );
    } 
  },
  {
    field: 'Name',
    headerName: 'Тип отходов',
    width: 250,
    editable: true,
  },
  {
    field: 'ApplicationUserEmail',
    headerName: 'Email',
    width: 250,
    editable: true,
  },
  {
    field: 'CreationDate',
    headerName: 'Дата разметки',
    width: 250,
    editable: true,
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
  },
  { 
    field: 'Id', 
    headerName: 'ID', 
    width: 250 
  }

];


type Props = {
  className: string
}

const MapList: React.FC<Props> = ({className}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [statusId, setStatusId] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState([]);
  const {currentUser } = useAuth()

  useEffect(()=>{
    console.log("start")
    getData();
  },[])

  const getData = async () =>{
    const {data: res} = await axios.get(apiURL + "Labeling/List", 
      {
        headers: 
          { "Authorization" : "Bearer " + currentUser?.token},
        params:
        {
          statusId: statusId,
        }
      })
    console.log(JSON.parse(res))
    setData(JSON.parse(res));
    setIsLoading(false);
  }

  const acceptTransactions = async (statusRes, messageTitle, messageText, sendEmail, forML) =>{
    setIsLoading(true);
    const {data: res} = await axios.post(apiURL + "Labeling/ChangeStatusArray", 
    {
      selected: selectedRows
    },
    {
      headers: 
        { 
          "Authorization" : "Bearer " + currentUser?.token,
          'Content-Type': 'application/json'
        },
      params:
      {
        statusId: statusRes,
        messageTitle,
        messageText,
        forML,
        sendEmail
      },
    })
    getData();
    alert(res);
    console.log(res)
}


  return (
    <div className={`card ${className}`}>
      {!isLoading 
      ?<div className='card-body py-3'>
      {data 
      ?<>
        <DataGrid
          getRowId={(row) => row.Id}
          rows={data}
          rowHeight={imageHeight}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel)
          }}
        />
        <Box sx={{ p: 2, m: 1 }}>
          <Button
            sx={{ p: 2, m: 1 }}
            color="success"
            variant="outlined"
            onClick={() => acceptTransactions(7, "Ok", "Ok", true, true)}
          >
            Подтвердить
          </Button>
          <Button
            sx={{ p: 2, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => acceptTransactions(7, "Ok", "Ok", true, false)}>
            Подтвердить, но не подходит для ML
          </Button>
          <Button
            sx={{ p: 2, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => acceptTransactions(6, "Ошибка в экодействии", "К сожалению, не можем принять Ваше фото. Действие совершено не в парке или лесу, как того требуют правила проекта", false, false)}>
            Отказать - без отправки сообщения
          </Button>
          <Button
            sx={{ p: 2, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => acceptTransactions(6, "Ошибка в экодействии", "К сожалению, не можем принять Ваше фото. Разметка сделана неточно - прямоугольник неточно описывает упавковку", true, false)}>
            Отказать - неточно
          </Button>
          <Button
            sx={{ p: 2, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => acceptTransactions(6, "Ошибка в экодействии", "К сожалению, не можем принять Ваше фото. Выбран некорретный тип отходов", true, false)}
            >
            Отказать - не тот тип отходов
          </Button>
        </Box>
      </>
      : 
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
      }
        </div>
          :      <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "100vh" }}
        >
          <CircularProgress />
        </Stack>
        }
    </div>
  )
}

export {MapList}
