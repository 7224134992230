/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { SubbotnikRatingList } from '../../../_metronic/partials/widgets'


const UsersPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
     <div className='col-xxl-12'>
        <SubbotnikRatingList
          className='card-xl-stretch mb-xl-12'
        />
      </div>
    </div>
   
  </>
)

const SubbotnikRating: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.RATING'})}</PageTitle>
      <UsersPage />
    </>
  )
}

export {SubbotnikRating}
