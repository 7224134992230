/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { apiURL, reusableCupProductId } from '../../../../constants'
import { mainColor } from '../../../../constants'
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Box, Button } from '@mui/material'
import { positions } from '@mui/system';
import { borders } from '@mui/system';
import { json } from 'stream/consumers'

const imageHeight = 180;
const imageWidth = 80;

const columns: GridColDef[] = [
  {
    field: 'BlobUrl',
    headerName: 'Фото',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 250,
    renderCell: (params) => {
      return (
        <Box
          component="img"
          sx={{
            height: 180,
            width: 100
          }}
          alt="The house from the offer."
          src={params.row.BlobUrlBefore}
        />
      );
    } 
  },
  {
    field: 'ApplicationUserName',
    headerName: 'Имя',
    width: 250,
    editable: true,
  },
  {
    field: 'ApplicationUserEmail',
    headerName: 'Email',
    width: 250,
    editable: true,
  },
  {
    field: 'CreationDate',
    headerName: 'Дата разметки',
    width: 250,
    editable: true,
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
  },
  { 
    field: 'Id', 
    headerName: 'ID', 
    width: 250 
  }
];


type Props = {
  className: string
}

const ReusableCupList: React.FC<Props> = ({className}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [statusId, setStatusId] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState([]);
  const {currentUser } = useAuth()

  useEffect(()=>{
    console.log("start")
    getData();
  },[])

  const getData = async () =>{
    const {data: res} = await axios.get(apiURL + "Subbotnik/ListReusableCup", 
      {
        headers: 
          { "Authorization" : "Bearer " + currentUser?.token},
        params:
        {
          statusId: statusId,
        }
      })
    console.log(JSON.parse(res))
    setData(JSON.parse(res));
    setIsLoading(false);
  }

  const acceptTransactions = async (statusRes, messageTitle, messageText, sendEmail) =>{
    setIsLoading(true);
    const {data: res} = await axios.post(apiURL + "Subbotnik/ChangeStatusArray", 
    {
      selected: selectedRows
    },
    {
      headers: 
        { 
          "Authorization" : "Bearer " + currentUser?.token,
          'Content-Type': 'application/json'
        },
      params:
      {
        statusId: statusRes,
        messageTitle,
        messageText,
        productId : reusableCupProductId,
        sendEmail
      },
    })
    getData();
    alert(res);
    console.log(res)
}


  return (
    <div className={`card ${className}`}>
      {!isLoading 
      ?<div className='card-body py-3'>
      {data 
      ?<>
        <DataGrid
          getRowId={(row) => row.Id}
          rows={data}
          rowHeight={imageHeight}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel)
          }}
        />
        <Box sx={{ p: 2, m: 1 }}>
          <Button
            sx={{ p: 2, m: 1 }}
            color="success"
            variant="outlined"
            onClick={() => acceptTransactions(7, "Ok", "Ok", true)}
          >
            Подтвердить
          </Button>
          <Button
            sx={{ p: 2, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => acceptTransactions(6, "Ошибка в экодействии", "К сожалению, не можем принять Вашу фотографию многоразового стакана. Она выполнена не по правилам Цифрового субботника", true)}
            >
            Отказать - некорректная фотография
          </Button>
        </Box>
      </>
      : 
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
      }
        </div>
          :      
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "100vh" }}
        >
          <CircularProgress />
        </Stack>
        }
    </div>
  )
}

export {ReusableCupList}
