/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  EcoinStatisticChart,
  UserStatisticChart,
  WasteParkWidget,
  CompanyStatisticWidget,
  WastePointWidget,
  WasteLabelingWidget,
  WasteRVMWidget,
  BagStatisticChart
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <UserStatisticChart
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-4'>
        <CompanyStatisticWidget
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-4'>
        <WasteParkWidget
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-4'>
        <WastePointWidget
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-4'>
        <WasteLabelingWidget
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-4'>
        <WasteRVMWidget
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-6'>
        <EcoinStatisticChart
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xxl-6'>
        <BagStatisticChart
          className='card-xl-stretch mb-xl-8'
        />
      </div>
    </div>
   
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
