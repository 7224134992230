/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { apiURL } from '../../../../constants'
import { mainColor } from '../../../../constants'
import {useIntl} from 'react-intl'
import axios from 'axios'

type Props = {
  className: string
}

const WasteRVMWidget: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [total, setTotal] = useState(0);
  const intl = useIntl()

  const refreshMode = async () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const {data: res} = await axios.get(apiURL + "analytics/RVMTransactionCount", {params: { month : 6}})
    setTotal(JSON.parse(res).total);

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, intl.formatMessage({id: 'CHART.WASTERVMCAPTION'}), JSON.parse(res).statistics))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      // if (chart) {
      //   chart.destroy()
      // }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
              {intl.formatMessage({id: 'CHART.WASTERVMCOUNT'})}
              </a>

              <div className='text-muted fs-7 fw-semibold'>{intl.formatMessage({id: 'CHART.WASTERVMDESCRIPTION'})}</div>
            </div>

            <div className={`fw-bold fs-3 text-${mainColor}`}>{total}</div>
          </div>
        </div>
        {/* end:  */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {WasteRVMWidget}

interface Values {
  minusMonth: string, 
  minusMonthCount: number, 
  collected: number,
  users: number
}

function getChartOptions(height: number, caption1 : string, res: Values[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: caption1,
        data: res.map(a => a.collected),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: res.map(a => a.minusMonth),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return  val + ''
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
