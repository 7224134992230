/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { apiURL } from '../../../../constants'
import { mainColor } from '../../../../constants'
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Box, Button } from '@mui/material'
import { positions } from '@mui/system';
import { borders } from '@mui/system';
import { json } from 'stream/consumers'

const imageHeight = 180;
const imageWidth = 80;

type Props = {
  className: string
}

const EcoinTransferList: React.FC<Props> = ({className}) => {



const columns: GridColDef[] = [
  { 
    field: 'Id', 
    headerName: 'ID',
    editable: true,
    width: 250 
  },
  {
    field: 'fullName',
    headerName: 'Имя',
    description: 'This column has a value getter and is not sortable.',
    editable: true,
    width: 100, 
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.FirstName || ''} ${params.row.LastName || ''}`,
  },
  {
    field: 'Email',
    headerName: 'Email',
    editable: true,
  },
  {
    field: 'CreationDate',
    headerName: 'Дата заявки',
    editable: true,
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
  },
  { 
    field: 'Phone', 
    headerName: 'Телефон', 
    editable: true,
  },
  {
    field: 'BonusBalace',
    headerName: 'Баланс',
    editable: true,
  },
  {
    field: 'AmountBonus',
    headerName: 'Сумма транзакции',
    editable: true,
  },
  {
    field: 'VkusVillLoyalityAccountId',
    headerName: 'Карта лояльности ВВ',
    editable: true,
  },
  {
    field: 'VkusVillTransactionThisMonth',
    headerName: 'Переводов в ВВ в этом месяце',
    editable: true,
  },
  {
    field: 'DodoTransactionThisMonth',
    headerName: 'Переводов в Додо в этом месяце',
    editable: true,
  },
  {
    field: 'CompanyName',
    headerName: 'Компания',
    editable: true,
  },
  {
    field: 'action',
    headerName: "Действие",
    width: 200, 
    renderCell: (params) => {
      return (
        <Box>
        <Button
          color="success"
          size="small"
          variant="outlined"
          onClick={() => acceptTransactions(5, "Ok", "Ok",  true, params.row.Id)}
        >
          Подтвердить
        </Button>
        <br/>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => acceptTransactions(6, "", "", false, params.row.Id)}>
          Отказать - без отправки сообщения
        </Button>
        <br/>
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() => acceptTransactions(6, "Невозможно совершить транзакцию", "Не указан номер телефона или карта лояльности для перевода в "+params.row.CompanyName + ", или номер телефона не привязан к карте лояльности", true, params.row.Id)}
          >
          Отказать - некорректая карта лояльности
        </Button>
        </Box>
      );
    } 
  }
];


  const [isLoading, setIsLoading] = useState(true);
  const [statusId, setStatusId] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState([]);
  const {currentUser } = useAuth()

  useEffect(()=>{
    console.log("start")
    getData();
  },[])

  const getData = async () =>{
    const {data: res} = await axios.get(apiURL + "Transaction/EcoinTransferList", 
      {
        headers: 
          { "Authorization" : "Bearer " + currentUser?.token},
        params:
        {
          statusId: statusId,
        }
      })
    console.log(JSON.parse(res))
    setData(JSON.parse(res));
    setIsLoading(false);
  }

  const acceptTransactions = async (statusRes, messageTitle, messageText, sendEmail, transactionId) =>{
    setIsLoading(true);
    const {data: res} = await axios.post(apiURL + "Transaction/ChangeStatusSingle", 
    {},
    {
      headers: 
        { 
          "Authorization" : "Bearer " + currentUser?.token,
          'Content-Type': 'application/json'
        },
      params:
      {
        transactionId,
        statusId: statusRes,
        messageTitle,
        messageText,
        sendEmail
      },
    })
    getData();
    alert(res);
    console.log(res)
}


  return (
    <div className={`card ${className}`}>
      {!isLoading 
      ?<div className='card-body py-3'>
      {data 
      ?<>
        <DataGrid
          getRowId={(row) => row.Id}
          rows={data}
          rowHeight={imageHeight}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel)
          }}
        />

      </>
      : 
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
      }
        </div>
          :      <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "100vh" }}
        >
          <CircularProgress />
        </Stack>
        }
    </div>
  )
}

export {EcoinTransferList}
