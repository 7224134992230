/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { useAuth } from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { apiURL } from '../../../../constants'
import { mainColor } from '../../../../constants'
import { Button } from '@mui/material'
import moment from "moment"
import {
  BarcodeFormat,
  DecodeHintType,
  BrowserMultiFormatReader,
  NotFoundException
} from "@zxing/library"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

type Props = {
  className: string
}

interface RVM{
  Id : string
  Name : string
}

let rvmId2 = ""; 

const AcceptTransactionsRVM: React.FC<Props> = ({className}) => {
  const {mode} = useThemeMode()
  const {currentUser, logout} = useAuth()
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [rvms, setRVMs] = useState<any[]>([]);
  const [rvmId, setRvmId] = useState(null);
  const intl = useIntl()
  const formats = [BarcodeFormat.DATA_MATRIX];
  const hints = new Map();
  hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
  const codeReader = new BrowserMultiFormatReader(hints);

  useEffect(() => {
    setDevice();
    getRVMList();
  }, [])

  const decode = () =>{
    codeReader.decodeFromVideoDevice(
      selectedDeviceId,
      "video",
      (result, err) => {
        if (result) {
          console.log(rvmId2)
          console.log(rvmId)
          acceptDM(result);
        }
        if (err && !(err instanceof NotFoundException)) {
          alert(err);
        }
      }
    )
  }

  const setDevice = async () => {
    let devices = await codeReader.listVideoInputDevices();
    setDevices(devices);
    // if (devices.length > 0) {
    //     
    // }
  };

  const acceptDM = async (code : any)=>{
    
    const {data: res} = await axios.post(apiURL + 'fundomat/AcceptDm', 
    {

    },
    {
      params: {
        code: code,
        fundomatId: rvmId2
      }
    });
    alert(res);
  }

  const getRVMList = async ()=>{
    const {data: res} = await axios.get(apiURL + 'fundomat/ListDMFundomats');
    console.log(JSON.parse(res))
    setRVMs(JSON.parse(res));
  }

  const handleDeviceChange = (e) =>{
    console.log(e)
    setSelectedDeviceId(e.deviceId);
    decode()
  }

  const handleRVMChange = (e) =>{
    console.log(e)
    //let rvm = e.target.value.toString()
    //console.log(rvm)
    setRvmId(e);
    rvmId2 = e;
  }

  return (
    <div className={`card ${className}`}>
    <Box m={2} sx={{width: "100%"}}>
      <Typography variant="body2" gutterBottom>
        Выберите камеру:
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleDeviceChange}
        sx={{width: "100%"}}
      >
        {devices.map((e) => {return (<MenuItem value={e.deviceId} key={e.deviceId}>{e.label}</MenuItem>)})}
      </Select>
    </Box>
    <Box m={2} sx={{width: "100%"}}>
      <Typography variant="body2" gutterBottom>
        Выберите фандомат:
      </Typography>
      {rvms.length > 0
      ?<Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={(e) => handleRVMChange(e.target.value)}
        sx={{width: "100%"}}
      >
        {rvms.map((e) => {return (<MenuItem value={e?.Id!} key={e?.Id!}>{e?.Name!}</MenuItem>)})}
      </Select>
      : <></>}
    </Box>
    <Box>
      <video  id="video" style={{width: "100%"}}/>
    </Box>
    </div>
  )
}

export {AcceptTransactionsRVM}