/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { WasteParkList } from '../../../_metronic/partials/widgets'
import { MapList } from '../../../_metronic/partials/widgets/tables/MapList'


const UsersPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-12'>
        <MapList
          className='card-xl-stretch mb-xl-8'
        />
      </div>
    </div>
   
  </>
)

const MapLabeling: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MAP'})}</PageTitle>
      <UsersPage />
    </>
  )
}

export {MapLabeling}
