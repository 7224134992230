/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

interface Claims {
  claimType: string, 
  claimValue: string
}

const initialClaims : Claims[] = [];

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()
  const [claims, setClaims] = useState(initialClaims);
  
  useEffect(() =>{
    console.log(currentUser)
    let claimsTemp:Claims[] =  currentUser?.claims!; 
    setClaims(claimsTemp);
    console.log(claimsTemp.filter(p => p.claimType == "company").filter(p => p.claimValue == "manage").length > 0)
  }, [])

  return (
    <>
    {claims.filter(p => p.claimType == "analytics").filter(p => p.claimValue == "all").length > 0
     ? 
     <>
     <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.MANAGE'})}</span>
        </div>
      </div>
      </>
      : <></>
    }

    {claims.filter(p => p.claimType == "user").filter(p => p.claimValue == "manage").length > 0
    ?<SidebarMenuItemWithSub
        to='/users'
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/users'
            title={intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            hasBullet={true}
          />
      </SidebarMenuItemWithSub>
      : <></>}

    {claims.filter(p => p.claimType == "company").filter(p => p.claimValue == "manage").length > 0
    ?<SidebarMenuItemWithSub
        to='/companies'
        title={intl.formatMessage({id: 'MENU.COMPANIES'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/companies'
            title={intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            hasBullet={true}
          />
      </SidebarMenuItemWithSub>
      :<></>}

    {claims.filter(p => p.claimType == "wastepark").length > 0
    ?
      <SidebarMenuItemWithSub
        to='/wastepark'
        title={intl.formatMessage({id: 'MENU.WASTEPARKS'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/wastepark/analytics'
            title={intl.formatMessage({id: 'MENU.STATISTICS'})}
            hasBullet={true}
          />
          {claims.filter(p => p.claimType == "wastepark").filter(p => p.claimValue == "manage").length > 0
          ?<SidebarMenuItem
            to='/wastepark/labeling'
            title={intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            hasBullet={true}
          />
          :<></>}
      </SidebarMenuItemWithSub>
      : <></>}


    {claims.filter(p => p.claimType == "labeling").length > 0
    ?
      <SidebarMenuItemWithSub
        to='/labeling'
        title={intl.formatMessage({id: 'MENU.LABELING'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/labeling/analytics'
            title={intl.formatMessage({id: 'MENU.STATISTICS'})}
            hasBullet={true}
          />
          {claims.filter(p => p.claimType == "labeling").filter(p => p.claimValue == "manage").length > 0
          ?
          <SidebarMenuItem
            to='/labeling/labeling'
            title={intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            hasBullet={true}
          />
          :<></>}
      </SidebarMenuItemWithSub>
      :<></>}

    {claims.filter(p => p.claimType == "ecoin").length > 0
    ?<SidebarMenuItemWithSub
        to='/ecoin'
        title={intl.formatMessage({id: 'MENU.ECOIN'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
        {claims.filter(p => p.claimType == "labeling").filter(p => p.claimValue == "manage").length > 0
        && currentUser?.companyId != null
        ?
          <SidebarMenuItem
            to='/ecoin/analytics'
            title={intl.formatMessage({id: 'MENU.STATISTICS'})}
            hasBullet={true}
          />
          :<></>}
          <SidebarMenuItem
            to='/ecoin/transfers'
            title={intl.formatMessage({id: 'MENU.TRANSFERS'})}
            hasBullet={true}
          />
      </SidebarMenuItemWithSub>
      :<></>}

      {claims.filter(p => p.claimType == "map").length > 0
      ?<SidebarMenuItemWithSub
        to='/map'
        title={intl.formatMessage({id: 'MENU.MAP'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/map/analytics'
            title={intl.formatMessage({id: 'MENU.STATISTICS'})}
            hasBullet={true}
          />
          {/* <SidebarMenuItem
            to='/map/labeling'
            title={intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            hasBullet={true}
          /> */}
      </SidebarMenuItemWithSub>
      : <></>}

      {claims.filter(p => p.claimType == "rvm").length > 0
      ?<SidebarMenuItemWithSub
        to='/rvm'
        title={intl.formatMessage({id: 'MENU.RVM'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
        {claims.filter(p => p.claimType == "rvm").filter(p => p.claimValue == "manage" || p.claimValue == "analytics").length > 0 
          ?<SidebarMenuItem
            to='/rvm/analytics'
            title={intl.formatMessage({id: 'MENU.STATISTICS'})}
            hasBullet={true}
          />
          :<></>}
          {claims.filter(p => p.claimType == "rvm").filter(p => p.claimValue == "manage").length > 0 
          ?
          <SidebarMenuItem
            to='/rvm/accepttransactions'
            title={intl.formatMessage({id: 'MENU.ACCEPTTRANSACTIONS'})}
            hasBullet={true}
          />:<></>}
      </SidebarMenuItemWithSub>
      :<></>}

    {claims.filter(p => p.claimType == "subbotnik").length > 0
     ? <SidebarMenuItemWithSub
        to='/subbotnik'
        title={intl.formatMessage({id: 'MENU.SUBBOTNIK'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/subbotnik/rating'
            title={intl.formatMessage({id: 'MENU.RATING'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/subbotnik/actions'
            title={intl.formatMessage({id: 'MENU.ACTIONS'})}
            hasBullet={true}
          />
          {claims.filter(p => p.claimType == "subbotnik").filter(p => p.claimValue == "manage").length > 0
          ?<>
          <SidebarMenuItem
            to='/subbotnik/plogging'
            title={intl.formatMessage({id: 'MENU.PLOGGING'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/subbotnik/30000steps'
            title={intl.formatMessage({id: 'MENU.30000STEPS'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/subbotnik/2bin'
            title={intl.formatMessage({id: 'MENU.2BIN'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/subbotnik/reusablecup'
            title={intl.formatMessage({id: 'MENU.REUSABLECAP'})}
            hasBullet={true}
          />
          </>
          :<></>}
      </SidebarMenuItemWithSub>
      :<></>}

    {/* {claims.filter(p => p.claimType == "support").length > 0
    && currentUser?.companyId != null
      ?<SidebarMenuItemWithSub
        to='/support'
        title={intl.formatMessage({id: 'MENU.SUPPORT'})}
        fontIcon='bi-archive'
        icon='element-plus'
      >
          <SidebarMenuItem
            to='/support/analytics'
            title={intl.formatMessage({id: 'MENU.STATISTICS'})}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/support/management'
            title={intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            hasBullet={true}
          />
      </SidebarMenuItemWithSub>
      :<></>} */}
{/* 
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-layers'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
