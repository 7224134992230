import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { UsersWrapper } from '../pages/users/UsersWrapper'
import { CompanyWraper } from '../pages/company/CompanyWrapper'
import { WasteParkAnalytics } from '../pages/wastepark/WasteParkAnalytics'
import { WasteParkLabeling } from '../pages/wastepark/WasteParkLabeling'
import { LabelingLabeling } from '../pages/labeling/LabelingLabeling'
import { LabelingAnalytics } from '../pages/labeling/LabelingAnalytics'
import { EcoinAnalytics } from '../pages/ecoin/EcoinAnalytics'
import { EcoinTransfers } from '../pages/ecoin/EcoinTransfers'
import { MapAnalytics } from '../pages/map/MapAnalytics'
import { MapLabeling } from '../pages/map/MapLabeling'
import { SubbotnikRating } from '../pages/subbotnik/SubbotnikRating'
import { Subbotnik2Bin } from '../pages/subbotnik/Subbotnik2Bin'
import { Subbotnik30000Steps } from '../pages/subbotnik/Subbotnik30000Steps'
import { SubbotnikPlogging } from '../pages/subbotnik/SubbotnikPlogging'
import { SubbotnikReusableCup } from '../pages/subbotnik/SubbotnikReusableCup'
import { SubbotnikActions } from '../pages/subbotnik/SubbotnikActions'
import { RvmAcceptTransactions } from '../pages/rvm/RvmAcceptTransactions'
import { RvmAnalytics } from '../pages/rvm/RvmAnalytics'



const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='users' element={<UsersWrapper />} />
        <Route path='companies' element={<CompanyWraper />} />
        
        <Route path='wastepark/analytics' element={<WasteParkAnalytics />} />
        <Route path='wastepark/labeling' element={<WasteParkLabeling />} />

        <Route path='labeling/analytics' element={<LabelingAnalytics />} />
        <Route path='labeling/labeling' element={<LabelingLabeling />} />


        <Route path='ecoin/analytics' element={<EcoinAnalytics />} />
        <Route path='ecoin/transfers' element={<EcoinTransfers />} />


        <Route path='map/analytics' element={<MapAnalytics />} />
        <Route path='map/labeling' element={<MapLabeling />} />


        <Route path='subbotnik/rating' element={<SubbotnikRating />} />
        <Route path='subbotnik/actions' element={<SubbotnikActions />} />
        <Route path='subbotnik/2bin' element={<Subbotnik2Bin />} />
        <Route path='subbotnik/30000steps' element={<Subbotnik30000Steps />} />
        <Route path='subbotnik/plogging' element={<SubbotnikPlogging />} />
        <Route path='subbotnik/reusablecup' element={<SubbotnikReusableCup />} />


        <Route path='rvm/analytics' element={<RvmAnalytics />} />
        <Route path='rvm/accepttransactions' element={<RvmAcceptTransactions />} />



        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
