/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { apiURL } from '../../../../constants'
import { mainColor } from '../../../../constants'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

const columns: GridColDef[] = [
  { field: 'Id', 
    headerName: 'ID', 
    width: 250 },
  {
    field: 'fullName',
    headerName: 'Имя',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 250,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.FirstName || ''} ${params.row.LastName || ''}`,
  },
  {
    field: 'Email',
    headerName: 'Email',
    width: 250,
    editable: true,
  },
  {
    field: 'CreationDate',
    headerName: 'Дата регистрации',
    width: 250,
    editable: true,
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
  },
  { 
    field: 'actions', 
    headerName: 'Actions', 
    width: 400, 
    renderCell: (params) => {
    return (
      <Button
        onClick={(e) => console.log(e, params.row)}
        variant="contained"
        style={{fontSize: 10}}
      >
        Заблокировать
      </Button>
    );
  } }

];


type Props = {
  className: string
}

const UsersTable: React.FC<Props> = ({className}) => {

  const[data, setData] = useState([]);

  useEffect(()=>{
    console.log("start")
    getData();
  },[])

  const getData = async () =>{
    const {data: res} = await axios.get(apiURL + "user/list", {params: { month : 6}})
    console.log(JSON.parse(res))
    setData(JSON.parse(res));
    console.log(data)
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
      {data.length>0 && data 
      ?<DataGrid
        getRowId={(row) => row.Id}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 40,
            },
          },
        }}
        pageSizeOptions={[40]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      : 
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress />
      </Stack>
      }
      </div>
    </div>
  )
}

export {UsersTable}
